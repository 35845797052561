import { useEffect, useState } from 'react';
import { formatDistanceStrict } from 'date-fns';
import { PromptSettingsLiveStatus } from 'src/components/Prompt/constants';
import { enhancePromptCardDateDistance } from '../utils/enhancePromptCardDateDistance';
import { getPromptCardStatusConfig } from '../utils/getPromptCardStatusConfig';

interface DistanceTextConfig {
  prefix: string;
  text: string;
}

export const usePromptCardDistanceTimer = (
  startDate: string,
  endDate: string,
  status: PromptSettingsLiveStatus,
): DistanceTextConfig => {
  const [displayDistanceText, setDisplayDistanceText] = useState('');

  const currentDate = new Date();

  const promptStatusConfig = getPromptCardStatusConfig(startDate, endDate);
  const { prefix, baseDate } = promptStatusConfig[status];

  const distance = baseDate ? formatDistanceStrict(currentDate, baseDate) : '';

  useEffect(() => {
    const displayDistance = enhancePromptCardDateDistance(distance);
    setDisplayDistanceText(displayDistance);
  }, [distance, status]);

  return { prefix, text: displayDistanceText };
};
