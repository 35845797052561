import React, { FC } from 'react';
import { useMutation } from '@apollo/client';
import { PromptCardV2 } from './PromptCardV2';
import { updateTerritoryPinnedAtQuery } from '../Territory/mutations/updateTerritoryPinnedAtQuery';
import { MAX_PINNED_PROMPTS } from '../PromptCard/constants';
import { showNotification } from '../NotificationBanner/showNotification';
import { MAX_PINNED_ARTICLES_PER_TERRITORY } from '../TerritoryArticlesSection/constants';
import { TPromptsData } from '../PromptsListSection/types';

type TPromptCardV2ContainerProps = {
  prompt: TPromptsData;
  canManagePinnedArticles: boolean;
  isPinningEnabled: boolean;
  showTerritory?: boolean;
};

export const PromptCardV2Container: FC<TPromptCardV2ContainerProps> = ({
  prompt,
  canManagePinnedArticles,
  isPinningEnabled,
  showTerritory,
}) => {
  const [updateTerritoryPinnedAt] = useMutation(updateTerritoryPinnedAtQuery, {
    variables: {
      id: prompt.id,
      data: {
        territoryPinnedAt: prompt?.territoryPinnedAt ? null : new Date().toISOString(),
      },
    },
  });

  const toggleTerritoryPin = async () => {
    try {
      await updateTerritoryPinnedAt();
    } catch (e) {
      if ((e as Error).message.includes(MAX_PINNED_PROMPTS)) {
        showNotification({
          message: `You can only have ${MAX_PINNED_ARTICLES_PER_TERRITORY} Prompts pinned.`,
          type: 'error',
          title: 'You’re out of Pins',
          displayIcon: false,
        });
      }
    }
  };

  return (
    <PromptCardV2
      prompt={prompt}
      canManagePinnedArticles={canManagePinnedArticles}
      isPinningEnabled={isPinningEnabled}
      onPinClick={toggleTerritoryPin}
      showTerritory={showTerritory}
    />
  );
};
