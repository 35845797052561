import React, { FC } from 'react';
import { Article } from 't2-graphql-types';
import { MessageCircleIcon } from '../Icons/MessageCircleIcon';
import { PostCardFooterList } from '../PostCard/PostCardFooterList';
import { PostCardFooterListItem } from '../PostCard/PostCardFooterListItem';
import { PinIcon } from '../Icons/PinIcon';
import { RepliesIcon } from '../Icons/RepliesIcon';
import ThreeDotsIcon from '../Icons/ThreeDotsIcon';
import Dropdown from '../Dropdown/Dropdown';
import { CardDropdownMenu } from '../CardDropdownMenu/CardDropdownMenu';
import Typo from '../Typography';

export const getPromptCardFooterList = (replies: number, commentsCount: number) => [
  {
    key: 'replies',
    name: `${replies || 0} replies`,
    icon: <RepliesIcon className="fill-t2-neutral-grey05 group-hover:fill-t2-neutral-grey06" />,
  },
  {
    key: 'comments',
    name: commentsCount || 0,
    icon: <MessageCircleIcon className="stroke-t2-neutral-grey05 group-hover:stroke-t2-neutral-grey06" />,
  },
];

export const getPromptDropdownMenuButtons = (territoryPinnedAt: string, onClick: () => void) => [
  {
    key: 'pin',
    text: territoryPinnedAt ? 'Unpin Prompt' : 'Pin Prompt',
    icon: <PinIcon className="w-4 h-4 self-start md:self-center fill-none stroke-t2-neutral-grey09" />,
    onClick,
  },
];

type TPromptCardV2FooterProps = {
  prompt: Article;
  canManagePinnedArticles?: boolean;
  isPinningEnabled?: boolean;
  onPinClick: () => void;
  showTerritory?: boolean;
};

export const PromptCardV2Footer: FC<TPromptCardV2FooterProps> = ({
  prompt,
  canManagePinnedArticles = true,
  isPinningEnabled = true,
  onPinClick,
  showTerritory,
}) => {
  const footerList = getPromptCardFooterList(prompt?.childArticlesCount, prompt?.commentsCount);
  const menuButtons = getPromptDropdownMenuButtons(prompt?.territoryPinnedAt, onPinClick);
  const territoryName = prompt?.territory?.name;

  return (
    <div className="flex flex-wrap w-full items-center justify-between">
      <PostCardFooterList>
        {footerList.map((item) => (
          <PostCardFooterListItem key={item.key}>
            <span>{item.icon}</span>
            <span>{item.name}</span>
          </PostCardFooterListItem>
        ))}
      </PostCardFooterList>
      <div className="flex items-center ml-auto">
        {isPinningEnabled && prompt?.territoryPinnedAt && (
          <PinIcon className="w-3 h-3 stroke-t2-primary-brandColor fill-t2-primary-brandColor -z-10 mr-2" />
        )}
        {canManagePinnedArticles && (
          <div className="z-30 h-6">
            <Dropdown
              label={<ThreeDotsIcon />}
              menu={<CardDropdownMenu buttons={menuButtons} />}
              position="right"
              menuContainerClassName="bottom-[35px] border border-t2-neutral-grey02 shadow-hoverShadow"
            />
          </div>
        )}
        {showTerritory && territoryName && (
          <div className="self-end py-2 pr-2">
            <Typo className="text-sm">{`t/${territoryName}`}</Typo>
          </div>
        )}
      </div>
    </div>
  );
};
