import { PromptSettingsLiveStatus } from 'src/components/Prompt/constants';

export const getPromptCardStatusConfig = (startDate: string, endDate: string) => {
  const formattedStartDate = new Date(startDate);
  const formattedEndDate = new Date(endDate);

  return {
    [PromptSettingsLiveStatus.UPCOMING]: {
      prefix: 'Starts in',
      baseDate: formattedStartDate,
    },
    [PromptSettingsLiveStatus.LIVE]: {
      prefix: 'Ends in',
      baseDate: formattedEndDate,
    },
    [PromptSettingsLiveStatus.COMPLETED]: {
      prefix: 'Completed',
      baseDate: null,
    },
  };
};
