import React, { FC } from 'react';
import cx from 'classnames';

type TRepliesIconProps = {
  className?: string;
};

export const RepliesIcon: FC<TRepliesIconProps> = ({ className }) => (
  <svg
    className={cx(className)}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.59975 1.16579C6.8208 0.944737 7.1792 0.944737 7.40025 1.16579L9.01485 2.78039C9.2359 3.00144 9.2359 3.35983 9.01485 3.58088L7.40025 5.19548C7.1792 5.41653 6.8208 5.41653 6.59975 5.19548L4.98515 3.58088C4.7641 3.35983 4.7641 3.00144 4.98516 2.78038L6.59975 1.16579Z"
      stroke=""
    />
    <path
      d="M10.4191 4.98515C10.6402 4.7641 10.9986 4.7641 11.2196 4.98515L12.8342 6.59975C13.0553 6.8208 13.0553 7.1792 12.8342 7.40025L11.2196 9.01485C10.9986 9.2359 10.6402 9.2359 10.4191 9.01485L8.80452 7.40025C8.58347 7.1792 8.58347 6.8208 8.80452 6.59975L10.4191 4.98515Z"
      stroke=""
    />
    <path
      d="M2.78039 4.98515C3.00144 4.7641 3.35983 4.7641 3.58088 4.98515L5.19548 6.59975C5.41653 6.8208 5.41653 7.1792 5.19548 7.40025L3.58088 9.01485C3.35983 9.2359 3.00144 9.2359 2.78038 9.01485L1.16579 7.40025C0.944737 7.1792 0.944737 6.8208 1.16579 6.59975L2.78039 4.98515Z"
      stroke=""
    />
    <path
      d="M6.59975 8.80452C6.8208 8.58347 7.1792 8.58347 7.40025 8.80452L9.01485 10.4191C9.2359 10.6402 9.2359 10.9986 9.01485 11.2196L7.40025 12.8342C7.1792 13.0553 6.8208 13.0553 6.59975 12.8342L4.98515 11.2196C4.7641 10.9986 4.7641 10.6402 4.98515 10.4191L6.59975 8.80452Z"
      stroke=""
    />
  </svg>
);
