import React, { FC } from 'react';
import Typo from '../Typography/Typo';
import { getMultilineEllipsisStyle } from '../PromptCard/utils/getMultilineEllipsisStyle';

type TPromptCardV2ContentProps = {
  title: string;
};

export const PromptCardV2Content: FC<TPromptCardV2ContentProps> = ({ title }) => (
  <div className="w-full my-4 md:min-h-[84px]">
    <Typo
      variant="small-p"
      className="!text-base min-w-0 md:!text-lg md:text-center multiline-ellipsis break-words"
      style={getMultilineEllipsisStyle(3)}
    >
      {title}
    </Typo>
  </div>
);
