import React, { FC } from 'react';
import cx from 'classnames';
import Typo from '../Typography/Typo';

import { TPromptSettingsProps } from '../PromptCard/types';
import { usePromptCardDistanceTimer } from './hooks/usePromptCardDistanceTImer';
import { PromptSettingsLiveStatus } from '../Prompt/constants';

type TPromptCardV2LiveStatusProps = {
  promptSettings: TPromptSettingsProps | null;
};

export const PromptCardV2LiveStatus: FC<TPromptCardV2LiveStatusProps> = ({ promptSettings }) => {
  if (!promptSettings) {
    return null;
  }
  const { startDate, endDate, liveStatus } = promptSettings;
  const formattedDistanceText = usePromptCardDistanceTimer(startDate, endDate, liveStatus);

  return (
    <div className="px-[10px] py-1 border border-t2-neutral-grey03 rounded-[12px] flex items-center gap-[6px]">
      <div
        className={cx('w-[6px] h-[6px] rounded-full', {
          'bg-t2-success-darkGreen animate-pulse !duration-100': liveStatus === PromptSettingsLiveStatus.LIVE,
          'bg-t2-warning-darkYellow': liveStatus === PromptSettingsLiveStatus.UPCOMING,
          hidden: liveStatus === PromptSettingsLiveStatus.COMPLETED,
        })}
        style={{ animationDuration: liveStatus === PromptSettingsLiveStatus.LIVE && '1s' }}
      />

      <Typo variant="small-p" className="flex items-center gap-1 !text-xs whitespace-nowrap">
        {formattedDistanceText.prefix}
        <span
          className={cx({
            'text-t2-success-darkGreen': liveStatus === PromptSettingsLiveStatus.LIVE,
            'text-t2-warning-darkYellow': liveStatus === PromptSettingsLiveStatus.UPCOMING,
          })}
        >
          {formattedDistanceText.text}
        </span>
      </Typo>
    </div>
  );
};
