import React, { FC } from 'react';
import { Article } from 't2-graphql-types';
import { getPromptPageUrl } from '../../services/pagesUrl/getPromptPageUrl';
import { BaseCard } from '../BaseCard/BaseCard';
import { PromptCardV2Header } from './PromptCardV2Header';
import { PromptCardV2Content } from './PromptCardV2Content';
import { PromptCardV2Footer } from './PromptCardV2Footer';

type TPromptCardV2Props = {
  prompt: Article;
  canManagePinnedArticles: boolean;
  isPinningEnabled: boolean;
  onPinClick: () => void;
  showTerritory?: boolean;
};

export const PromptCardV2: FC<TPromptCardV2Props> = ({
  prompt,
  canManagePinnedArticles,
  isPinningEnabled,
  onPinClick,
  showTerritory,
}) => (
  <BaseCard url={getPromptPageUrl(prompt.slug)} cardType="prompt">
    <PromptCardV2Header prompt={prompt} />
    <PromptCardV2Content title={prompt.title as string} />
    <PromptCardV2Footer
      prompt={prompt}
      canManagePinnedArticles={canManagePinnedArticles}
      isPinningEnabled={isPinningEnabled}
      onPinClick={onPinClick}
      showTerritory={showTerritory}
    />
  </BaseCard>
);
