import React, { FC } from 'react';
import { Article } from 't2-graphql-types';
import { UserDetail } from '../UserDetail/UserDetail';
import { PromptCardV2LiveStatus } from './PromptCardV2LiveStatus';
import Tooltip from '../Tooltip/Tooltip';
import Typo from '../Typography/Typo';
import { formatPromptSettingsDate } from '../PromptCard/utils/formatPromptSettingsDate';
import getUserDisplayName from '../../../utils/getUserDisplayName';
import { getUserPageUrl } from '../../services/pagesUrl/getUserPageUrl';
import { UnstyledTooltip } from '../Tooltip/UnstyledTooltip';
import { PROFILE_CARD_HOVER_DELAY } from '../ProfileCard/constants';
import { T2ProfileCardContainer } from '../ProfileCard/T2ProfileCardContainer';

type TPromptCardV2HeaderProps = {
  prompt: Article;
};

export const PromptCardV2Header: FC<TPromptCardV2HeaderProps> = ({ prompt }) => (
  <div className="flex w-full items-center justify-between">
    <div className="z-[1]">
      <UnstyledTooltip
        direction="right"
        floatingUIHover={PROFILE_CARD_HOVER_DELAY}
        trigger={
          <UserDetail
            displayName={getUserDisplayName(prompt?.author)}
            href={getUserPageUrl(prompt?.author?.username)}
            profileImage={prompt.author?.profileImage}
            size="small"
            textSize="small"
            displayNameClassName="!ml-2"
          />
        }
      >
        <T2ProfileCardContainer id={prompt?.author?.id as string} />
      </UnstyledTooltip>
    </div>
    <Tooltip
      direction="bottom"
      cursor="default"
      trigger={
        <div className="relative z-20 cursor-pointer">
          <PromptCardV2LiveStatus promptSettings={prompt?.promptSettings} />
        </div>
      }
    >
      <Typo variant="small-p" className="!text-sm text-center">
        {formatPromptSettingsDate(prompt?.promptSettings?.startDate)} -{' '}
        {formatPromptSettingsDate(prompt?.promptSettings?.endDate)}
      </Typo>
    </Tooltip>
  </div>
);
