export const enhancePromptCardDateDistance = (distance: string): string => {
  const replacements: [RegExp, string][] = [
    [/months?/g, 'mo'],
    [/days?/g, 'd'],
    [/hours?/g, 'h'],
    [/minutes?/g, 'm'],
    [/seconds?/g, '1 min'],
  ];

  return replacements.reduce((result, [pattern, replacement]) => result.replace(pattern, replacement), distance);
};
