import React from 'react';
import { Placeholder } from '../Placeholder/Placeholder';

const PromptCardV2LoaderHeader = () => (
  <div className="flex w-full items-center flex-row gap-2">
    <Placeholder className="h-6 w-6 rounded-full bg-t2-neutral-grey03" />
    <Placeholder className="h-[16px] w-[100px] rounded-md" />
    <Placeholder className="ml-auto h-[16px] w-[70px] rounded-md" />
  </div>
);

const PromptCardV2LoaderContent = () => (
  <div className="flex flex-col gap-2">
    <Placeholder className="h-[16px] w-4/5 rounded-md" />
    <Placeholder className="h-[16px] w-full rounded-md" />
    <Placeholder className="h-[16px] w-3/5 rounded-md" />
  </div>
);

const PromptCardV2LoaderFooter = () => (
  <div className="flex flex-row gap-2">
    <Placeholder className="h-[16px] w-[100px] rounded-md" />
  </div>
);

export const PromptCardV2Loader = () => (
  <div className="relative p-4 flex flex-col md:min-h-[385px] md:justify-between tems-start gap-4 md:gap-0 bg-t2-neutral-grey02 rounded-[16px] animate-pulse">
    <PromptCardV2LoaderHeader />
    <PromptCardV2LoaderContent />
    <PromptCardV2LoaderFooter />
  </div>
);
